<template>
    <div>
        <myBreadcrumb :nav="nav"></myBreadcrumb>
        <div id="wrap">
            <video id="player-container-id" preload="auto" playsinline webkit-playsinline>
            </video>
        </div>
    </div>
</template>

<script>
import TCPlayer from 'tcplayer.js';
import 'tcplayer.js/dist/tcplayer.min.css';

export default {
    data() {
        return {
            playerUrl: '',
            nav: [
                {
                    title: '直播',
                    to: { path: '/' },
                },
                {
                    title: '直播详情',
                },
            ],
        };
    },
    created() {
        this.playerUrl = this.$route.query.playerUrl;
    },
    mounted() {
        this.initPlayer();
    },
    methods:{
        initPlayer() {
            var player = TCPlayer('player-container-id', {}); // player-container-id 为播放器容器 ID，必须与 html 中一致
            player.src(this.playerUrl); // url 播放地址
            // player.src('https://1318960601.vod-qcloud.com/ed1d63e5vodcq1318960601/3360627a3270835010581334367/f0.flv');
            // player.src('http://byxwhgliveplay.ltzsgl.com/live/byx20230713001325.flv');
        }
    }
}
</script>

<style lang="less" scoped>
    html,body{
      margin: 0;
      padding: 0;
    }
    
    #player-container-id {
      width: 100%;
      max-width: 100%;
      height: 0;
      padding-top: 56.25%;
    }
    #wrap {
      width: 80%;
      margin: 0 auto;
    }
    
    @media only screen and (min-device-pixel-ratio: 2), only screen and (-webkit-min-device-pixel-ratio: 2) {
      .tcp-logo-img {
        width: 50%;
      }
    }
</style>